import { Link } from 'gatsby';
import React, { FC, useState, useEffect } from 'react'
import GeneralButton from '../components/atoms/GeneralButton';
import Layout from '../components/Layout';
import { AndroidApplicationURL, iOSApplicationURL } from '../constant/URL';
import { detectDevice } from "../functions/detectDevice"

type Props = {
}

const LeadAppPage: FC<Props> = () => {
    const [ isiOS, setIsiOS ] = useState( false );
    const [ deviceDetected, setDeviceDetected ] = useState( false );

    useEffect( () => {
        const { isiOS: isiOS__ } = detectDevice()
        setIsiOS( isiOS__ )
        setDeviceDetected( true )
    }, [] )

    useEffect( () => {
        deviceDetected && navigateToApp()
    }, [ deviceDetected ] )

    const navigateToApp = () => {
        window.location.href = isiOS ?
            iOSApplicationURL :
            AndroidApplicationURL
    }
    return (
        <Layout footerHide>
            <div style={ { display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" } }>
                <div style={ { textAlign: "center" } }>
                    <h1 style={ { marginBottom: 16, textAlign: "center", fontSize: 28 } }>ClubCloud</h1>
                    <GeneralButton title="アプリのストアに移動する" onClick={ navigateToApp } style={ { marginBottom: 40 } } />
                    <Link to="/">トップページ</Link>
                </div>
            </div>
        </Layout>

    )
}
export default LeadAppPage
